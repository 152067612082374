.keyboard-base {
  max-width: 1085px;
  padding: 10px;
  position: absolute;
  bottom: calc(0px - 115px);
  left: 328px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.281);
  border-radius: 4px;
  display: grid;
  grid-template-columns: repeat(30, 16px);
  grid-template-rows: repeat(5, 41px);
  grid-gap: 5px;
  z-index:200;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  cursor: pointer;
}
.keyboard-base:hover {
  outline: 1px solid white;
}
.keyboard-base:active {
  outline: 1px solid grey;
}


.keyboard-base .key {
  background-color: rgba(255, 255, 255, 0.761);
  border: 1px solid rgb(0, 0, 0);
  border-radius: 20px;
  grid-column: span 2;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  padding-top: 10px;
  opacity: 0.7;
}

.keyboard-base .delete {
  grid-column: span 2;
}

.keyboard-base .tab {
  grid-column: span 3;
}

.keyboard-base .backslash {
  grid-column: span 3;
}

.keyboard-base .capslock {
  grid-column: span 3;
}

.keyboard-base .return {
  grid-column: span 5;
}

.keyboard-base .leftshift {
  grid-column: span 5;
}

.keyboard-base .rightshift {
  grid-column: span 5;
}

.keyboard-base .leftctrl {
  grid-column: span 4;
}

.keyboard-base .rightctrl {
  grid-column: span 3;
}

.keyboard-base .command {
  grid-column: span 3;
}

.keyboard-base .space {
  grid-column: span 13;
}
.keyboard-base .pressed {
  background-color: rgb(3, 192, 255);
}
.keyboard-base .int {
  opacity: 1;
}