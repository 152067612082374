.controllerBase {
  width: 350px;
  padding: 10px;
  position: absolute;
  bottom: calc(0px - 180px);
  left: calc(100vw - 188px);
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.281);
  border-radius: 8px;
  z-index:200;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  cursor: pointer;
  display:flex;
  flex-direction: column;
  align-items: center;
  color: white;
}
.controllerBase h4 {
  padding: 0;
  margin: 0 0 10px 0;
  font-size: 26px;
}
.controllerBase:hover {
  outline: 1px solid white;
}
.controllerBase:active {
  outline: 1px solid grey;
}