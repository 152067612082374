body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  background: #272730;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
canvas, body, html {
  height: 100vh;
  width: 100%;
} 
#debugGUI { position: absolute; top: 2px; right: 2px; z-index: 9999; }
.screenContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 100;
}
.crosshair {
  height: 5px;
  width: 5px;
  background-color: white;
  border-radius: 50%;
  border: solid 1px gray;
  margin: auto;
  pointer-events: none;
}

.loading {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: #171717;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-bar-container {
  width: 200px;
  height: 3px;
  background: #272727;
}

.loading-bar {
  height: 3px;
  background: white;
}
.Label {
  cursor: pointer;
  font-weight: 400;
  background-color: rgba(0,0,0,0.4);
  position:absolute;
  width: 100%;
  height: 100vh;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-top: calc(50vh - 30px);
  color: white;
  font-size: 40px;
  font-family: roboto;
}